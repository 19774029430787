<template>
  <div v-loading="leftLoading">
    <el-card>
      <div class="cards">
        <div class="card_left">
          <div class="top">
            <el-input
              v-model="filterDept"
              placeholder="请输入关键词查找组织"
            ></el-input>
            <el-button @click="addTopLevelTreeData" class="create_btn">
              <i class="el-icon-plus"></i>
            </el-button>
          </div>
          <div class="categoryCompany">
            <el-tree
              node-key="deptCode"
              :expand-on-click-node="false"
              ref="tree"
              default-expand-all
              :filter-node-method="filterNode"
              :highlight-current="true"
              :data="treeData"
              :renderContent="renderContent"
              :props="defaultProps"
              @node-click="handleNodeClick"
            ></el-tree>
          </div>
        </div>
        <div class="card_right">
          <div class="top">{{ activeObj.deptName }}</div>
          <div class="two" style="display: flex">
            <!-- v-show="checkAuth(getCompanyAuthCodeByName(item.label))" -->
            <div
              v-show="
                checkAuth(getCompanyAuthCodeByName(item.label)) &&
                (currentRowType == item.currentRowType ||
                  item.label == '人员管理')
              "
              v-for="(item, index) in tabList"
              :key="index"
              @click="activeIndex = item.activeIndex"
              :class="{
                'item tabsList': true,
                activeItem: activeIndex == item.activeIndex,
              }"
            >
              {{ item.label }}
            </div>
          </div>
          <!-- 人员管理 -->
          <div class="table_box" v-show="activeIndex == 1 && showRight">
            <div class="table_title">
              <div class="left">
                <el-input
                  v-model="queryInfo.condition.keyword"
                  clearable
                  @keyup.enter.native="getTable()"
                  placeholder="搜索关键词"
                ></el-input>
                <el-button @click="getTable()">搜索</el-button>
              </div>
              <div class="right">
                <el-button
                  :disabled="!checkAuth(getCompanyMemberAuthCodeByName('新增'))"
                  class="new_btn"
                  @click="
                    () => {
                      dialog_CreateEmployee = true;
                    }
                  "
                >
                  <i class="el-icon-plus"></i><span>新增员工</span>
                </el-button>
              </div>
            </div>
            <div class="table_main">
              <el-table
                border
                fit
                :data="tableData"
                v-loading="tableLoading"
                height="calc(100vh - 300px)"
                width="100%"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column label="姓名" align="center">
                  <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                    <span v-if="scope.row.isManager">(负责人)</span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="部门"
                  prop="deptName"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="岗位"
                  prop="postName"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="登录账号"
                  prop="loginName"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="联系方式"
                  prop="phone"
                  align="center"
                ></el-table-column>
                <el-table-column label="性别" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.sex == 1">男</span>
                    <span v-if="scope.row.sex == 2">女</span>
                  </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                  <template slot-scope="scope">
                    <span class="online" v-if="scope.row.status == 1"
                      >在职</span
                    >
                    <span v-if="scope.row.status == 2" style="color: #d7d7d7"
                      >已离职</span
                    >
                  </template>
                </el-table-column>
                <el-table-column label="操作" align="center" width="120px">
                  <template slot-scope="scope">
                    <span
                      @click="cancelOne(scope.row)"
                      v-if="
                        scope.row.isManager == 1 &&
                        checkAuth(getCompanyMemberAuthCodeByName('解绑'))
                      "
                      style="color: #d9001b; cursor: pointer"
                      >解绑</span
                    >
                    <span
                      @click="bindOne(scope.row)"
                      v-if="
                        scope.row.isManager == 0 &&
                        checkAuth(getCompanyMemberAuthCodeByName('绑定'))
                      "
                      style="color: #02a7f0; cursor: pointer"
                      >绑定</span
                    >
                    <span
                      @click="editOne(scope.row)"
                      v-if="checkAuth(getCompanyMemberAuthCodeByName('编辑'))"
                      style="margin-left: 6px; color: #f59a23; cursor: pointer"
                      >编辑</span
                    >
                    <span
                      @click="deleteOne(scope.row.id)"
                      v-if="checkAuth(getCompanyMemberAuthCodeByName('删除'))"
                      style="color: #d9001b; cursor: pointer; margin-left: 6px"
                      >删除</span
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.currPage"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
          <!-- 公章登记 -->
          <!-- <div
            class="table_box"
            v-if="currentRowType == 0 && activeIndex == 4 && showRight"
          >
            <div class="table_title">
              <div class="left">
                <el-input clearable placeholder="搜索关键词"></el-input>
                <el-button @click="getStampList">搜索</el-button>
              </div>
              <div class="right">
                <el-button class="new_btn" @click="addOfficialseal">
                  <i class="el-icon-plus"></i><span>新增</span>
                </el-button>
              </div>
            </div>
            <div class="table_main">
              <el-table
                border
                fit
                :data="tableDataStamp"
                v-loading="tableLoadingStamp"
                height="calc(100vh - 300px)"
                width="100%"
              >
                <el-table-column
                  fixed="left"
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column
                  fixed="left"
                  label="公章名称"
                  prop="officialSealName"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="公章序号"
                  prop="officialSeaNo"
                  align="center"
                ></el-table-column>
                <el-table-column label="公章类型" align="center">
                  <template slot-scope="{ row }">
                    <span v-if="row.officialType !== null">
                      {{
                        officialsealTypeOptions.find(
                          (option) => option.value === row.officialType
                        ).label
                      }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  label="刻章日期"
                  prop="createTime"
                  align="center"
                ></el-table-column>
                <el-table-column
                  label="保管人"
                  prop="custodian"
                  align="center"
                ></el-table-column>
                <el-table-column label="是否作废" align="center">
                  <template slot-scope="scope">
                    <span style="color: green" v-if="scope.row.isEffective == 0"
                      >有效</span
                    >
                    <span v-if="scope.row.isEffective == 1" style="color: red"
                      >作废</span
                    >
                  </template>
                </el-table-column>
                <el-table-column label="补刻次数" prop="count" align="center">
                </el-table-column>
                <el-table-column
                  label="操作"
                  fixed="right"
                  align="center"
                  width="120px"
                >
                  <template slot-scope="scope">
                    <span
                      @click="editOneStamp(scope.row)"
                      style="margin-left: 6px; color: #f59a23; cursor: pointer"
                      >编辑</span
                    >

                    <el-popconfirm
                      title="确定删除吗？"
                      @onConfirm="delOneStamp(scope.row.id)"
                    >
                      <span
                        slot="reference"
                        style="
                          color: #d9001b;
                          cursor: pointer;
                          margin-left: 6px;
                        "
                        >移除</span
                      >
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChangeOfficial"
                @current-change="handleCurrentChangeOfficial"
                :current-page="officalQueryInfo.currPage"
                :page-sizes="[50, 100, 200, 500]"
                :page-size="officalQueryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="officalTotal"
              >
              </el-pagination>
            </div>
          </div> -->
          <!-- 部门职责 -->
          <div v-if="currentRowType == 1 && activeIndex == 2 && showRight">
            <div class="block-info">部门职责</div>
            <div class="dept-box">
              <div class="box-title">
                <span>{{ activeObj.deptName }}━━━部门职责</span>
                <div class="right-btn">
                  <el-button
                    type="primary"
                    @click="editDept"
                    icon="el-icon-edit"
                    >编 辑</el-button
                  >
                </div>
              </div>
              <div class="box-inner">
                <quill-editor
                  @focus="editorFocus($event)"
                  class="ql-editor noToolBar"
                  v-model="deptContent"
                  ref="myQuillEditor"
                  :options="editorOption"
                >
                </quill-editor>
              </div>
            </div>
          </div>
          <!-- 对公信息 -->
          <!-- <div v-if="currentRowType == 0 && activeIndex == 5 && showRight">
            <div class="block-info">对公信息</div>
            <div class="dept-box">
              <div class="box-title flex-start">
                <span>{{ currentRowTree.deptName }}━━━对公信息</span>
                <div class="right-btn">
                  <el-button
                    type="primary"
                    @click="saveAccountForm"
                    icon="el-icon-edit"
                    v-permission="'account_save'"
                    >保 存</el-button
                  >
                </div>
              </div>
              <el-form
                :model="accountForm"
                ref="accountForm"
                label-width="90px"
                class="line-height44"
              >
                <el-row>
                  <el-col :span="20">
                    <el-form-item prop="accountNo" label="账号">
                      <el-input v-model="accountForm.accountNo"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="20">
                    <el-form-item label="户号" prop="householdNo">
                      <el-input v-model="accountForm.householdNo"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="20">
                    <el-form-item label="税号" prop="taxNo">
                      <el-input v-model="accountForm.taxNo"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="20">
                    <el-form-item label="币种">
                      <el-select v-model="accountForm.currency">
                        <el-option label="人民币" value="人民币"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="20" v-permission="'account_save'">
                    <el-form-item label="余额">
                      <el-input-number
                        :min="0"
                        controls-position="right"
                        v-model.number="accountForm.balance"
                      ></el-input-number>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
          </div> -->
          <!-- 岗位管理   -->
          <div
            class="postManage"
            v-show="currentRowType == 0 && activeIndex == 3 && showRight"
          >
            <postManage :deptCode="activeObj.deptCode" />
          </div>

          <!-- 车辆管理 -->
          <!-- <div
            class="table_box"
            v-if="currentRowType == 0 && activeIndex == 6 && showRight"
          >
            <div class="table_title">
              <div class="left">
                <el-input
                  clearable
                  v-model="carQueryInfo.condition.carNum"
                  placeholder="搜索车牌号"
                ></el-input>
                <el-button @click="getCarList">搜索</el-button>
              </div>
              <div class="right">
                <el-button class="new_btn" @click="addCar">
                  <i class="el-icon-plus"></i><span>新增</span>
                </el-button>

              </div>
            </div>
            <div class="table_main">
              <el-table
                border
                fit
                :data="tableDataCar"
                v-loading="tableLoadingCar"
                height="calc(100vh - 300px)"
                width="100%"
              >
                <el-table-column
                  fixed="left"
                  type="index"
                  label="序号"
                  align="center"
                ></el-table-column>
                <el-table-column fixed="left" label="车辆图片" align="center">
                  <template slot-scope="{ row }">
                    <el-image
                      :preview-src-list="[row.imgUrl]"
                      v-if="row.imgUrl"
                      :src="row.imgUrl"
                    ></el-image>
                  </template>
                </el-table-column>
                <el-table-column
                  fixed="left"
                  label="车牌号"
                  prop="carNum"
                  align="center"
                >
                  <template slot-scope="{ row }">
                    <span>{{ row.carNum }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="品牌" prop="brand" align="center">
                  <template slot-scope="{ row }">
                    <span>{{ row.brand }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="状态" align="center">
                  <template slot-scope="{ row }">
                    <el-tag type="success" v-if="row.staus == 0">空闲</el-tag>
                    <el-tag type="success" v-if="row.staus == 1">待出发</el-tag>
                    <el-tag type="success" v-if="row.staus == 2">待回归</el-tag>
                  </template>
                </el-table-column>
                <el-table-column label="购车日期" prop="buyDate" align="center">
                  <template slot-scope="{ row }">
                    <span>{{
                      row.buyDate ? formatData(row.buyDate) : ""
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="车辆描述" prop="carDesc" align="center">
                  <template slot-scope="{ row }">
                    <span>{{ row.carDesc }}</span>
                  </template>
                </el-table-column>

                <el-table-column label="操作" align="center" width="120px">
                  <template slot-scope="scope">
                    <span
                      @click="editCar(scope.row)"
                      style="margin-left: 6px; color: #f59a23; cursor: pointer"
                      >编辑</span
                    >

                    <el-popconfirm
                      title="确定删除吗？"
                      @onConfirm="deleteOneCar(scope.row.id)"
                    >
                      <span
                        slot="reference"
                        style="
                          color: #d9001b;
                          cursor: pointer;
                          margin-left: 6px;
                        "
                        >移除</span
                      >
                    </el-popconfirm>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChangeCar"
                @current-change="handleCurrentChangeCar"
                :current-page="carQueryInfo.currPage"
                :page-sizes="[50, 100, 200, 500]"
                :page-size="carQueryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="carTotal"
              >
              </el-pagination>
            </div>
          </div> -->
          <!-- 无权限页页面 -->
          <!-- <div class="no-power" v-show="noAuth">
              <div class="no-power-text">抱歉，您没有权限查看该页面</div>
            </div> -->
        </div>
      </div>
    </el-card>
    <!-- 创建部门 -->
    <el-dialog
      :title="labelType == 'add' ? '创建组织' : '编辑组织'"
      :visible.sync="dialog_CreateDept"
      width="520px"
    >
      <el-form>
        <el-form-item label="组织名称" required>
          <el-input
            style="width: 320px"
            @keyup.enter.native="createDeptBtn"
            placeholder="请输入组织名称"
            v-model="deptName"
          ></el-input>
        </el-form-item>
        <el-form-item label="组织类型" required>
          <el-radio-group v-model="deptType">
            <el-radio label="0">公司</el-radio>
            <el-radio label="1">部门</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_CreateDept = false">取 消</el-button>
        <el-button type="primary" @click="createDeptBtn">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="创建员工账号"
      :visible.sync="dialog_CreateEmployee"
      width="520px"
    >
      <el-form :inline="true" label-width="90px">
        <el-form-item label="登录账号" required>
          <el-input
            style="width: 320px"
            v-model="employeeForm.loginName"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item label="姓名" required>
          <el-input
            style="width: 320px"
            v-model="employeeForm.name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="employeeForm.sex" label="1">男</el-radio>
          <el-radio v-model="employeeForm.sex" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="岗位名称" required>
          <el-select
            filterable
            style="width: 320px"
            placeholder="请选择岗位名称"
            v-model="employeeForm.postName"
          >
            <el-option
              v-for="item in postOptions"
              :value="item.postName"
              :label="item.postName"
              :key="item.id"
            ></el-option>
          </el-select>
          <!-- <el-input style="width:320px" v-model="employeeForm.postName"
    placeholder="请输入岗位名称" ></el-input> -->
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="employeeForm.roleCodeList"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.roleCode"
              :label="item.roleName"
              :value="item.roleCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考勤设置">
          <el-radio v-model="employeeForm.restType" :label="1">单休</el-radio>
          <el-radio v-model="employeeForm.restType" :label="2">双休</el-radio>
        </el-form-item>
        <el-form-item label="考勤设置">
          <el-radio v-model="employeeForm.signNotice" :label="1">推送</el-radio>
          <el-radio v-model="employeeForm.signNotice" :label="0"
            >不推送</el-radio
          >
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_CreateEmployee = false">取 消</el-button>
        <el-button type="primary" @click="createEmployeeBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑 -->
    <el-dialog title="编辑" :visible.sync="dialog_edit" width="520px">
      <el-form :inline="true" label-width="90px">
        <el-form-item label="姓名" required>
          <el-input
            style="width: 320px"
            v-model="editObj.name"
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item label="部门" required>
          <el-select
            value-key="deptCode"
            @change="forceUpdate"
            v-model="editObj.dept"
            style="width: 320px"
            placeholder="请选择部门"
          >
            <el-option
              v-for="(item, index) in typeList"
              :key="index"
              :label="item.deptName"
              :value="{ deptCode: item.deptCode, deptName: item.deptName }"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="性别">
          <el-radio v-model="editObj.sex" label="1">男</el-radio>
          <el-radio v-model="editObj.sex" label="2">女</el-radio>
        </el-form-item>
        <el-form-item label="岗位名称" required>
          <el-select
            filterable
            style="width: 320px"
            placeholder="请选择岗位名称"
            v-model="editObj.postName"
          >
            <el-option
              v-for="item in postOptions"
              :value="item.postName"
              :label="item.postName"
              :key="item.id"
            ></el-option>
          </el-select>
          <!-- <el-input style="width:320px" v-model="editObj.postName"
    placeholder="请输入岗位名称" ></el-input> -->
        </el-form-item>
        <el-form-item label="联系方式" required>
          <el-input
            style="width: 320px"
            v-model="editObj.phone"
            placeholder="请输入联系方式"
          ></el-input>
        </el-form-item>
        <el-form-item label="状态">
          <el-radio v-model="editObj.status" :label="1">在职</el-radio>
          <el-radio v-model="editObj.status" :label="2">离职</el-radio>
        </el-form-item>
        <el-form-item v-if="editObj.status == 2" label="离职交接">
          <el-select v-model="editObj.toUserCode" filterable>
            <el-option-group
              v-for="group in toUserOptions"
              :key="group.deptCode"
              :label="group.deptName"
            >
              <el-option
                v-for="item in group.userInfoList"
                :key="item.userCode"
                :label="item.name"
                :value="item.userCode"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="角色">
          <el-select
            v-model="editObj.roleCodeList"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in roleOptions"
              :key="item.roleCode"
              :label="item.roleName"
              :value="item.roleCode"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考勤设置">
          <el-radio v-model="editObj.restType" :label="1">单休</el-radio>
          <el-radio v-model="editObj.restType" :label="2">双休</el-radio>
        </el-form-item>
        <el-form-item label="打卡推送">
          <el-radio v-model="editObj.signNotice" :label="1">推送</el-radio>
          <el-radio v-model="editObj.signNotice" :label="0">不推送</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialog_edit = false">取 消</el-button>
        <el-button type="primary" @click="editBtn">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 部门职责 -->
    <el-dialog
      v-dialogDrag
      custom-class="deptDialog"
      center
      :visible.sync="dialogDept"
      width="80%"
    >
      <template slot="title">
        <span>{{ activeObj.deptName }}━━━部门职责</span>
      </template>
      <quill-editor
        class="ql-editor-dialog"
        v-model="deptContentDialog"
        placeholder="暂无描述"
        ref="quillEditor"
        :options="editorOption"
      >
      </quill-editor>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDept = false">取 消</el-button>
        <el-button type="primary" @click="saveDept">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 公章记录 -->
    <el-dialog
      v-dialogDrag
      :title="officialsealType == 'add' ? '新增' : '编辑'"
      :visible.sync="dialogOfficialseal"
      width="720px"
    >
      <el-form :inline="true" label-width="90px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="公章类型">
              <el-select
                v-model="officalForm.officialType"
                placeholder="请选择公章类型"
              >
                <el-option
                  v-for="(item, index) in officialsealTypeOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公章名称">
              <el-input
                style="width: 100%"
                v-model="officalForm.officialSealName"
                placeholder="请输入公章名称"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="公章编号" required>
              <el-input
                style="width: 100%"
                v-model="officalForm.officialSeaNo"
                placeholder="请输入公章编号"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="刻章日期">
              <el-date-picker
                style="width: 210px"
                v-model="officalForm.officialDate"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
                placeholder="请选择刻章日期"
                default-time="12:00:00"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="经办人">
              <el-cascader
                ref="operatorUser"
                :props="operatorProps"
                v-model="officalForm.operatorUserCode"
                :options="custodianOptions"
                :show-all-levels="false"
              ></el-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="保管人">
              <el-cascader
                ref="custodian"
                :props="custodianProps"
                v-model="officalForm.custodianUserCode"
                :options="custodianOptions"
                :show-all-levels="false"
              ></el-cascader>
              <!-- <el-input v-model="officalForm.custodian" placeholder="请输入保管人">
    </el-input> -->
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="补刻次数">
              <el-input-number
                :min="0"
                :precision="0"
                :step="1"
                v-model="officalForm.count"
                placeholder="请输入刻章此处"
              >
              </el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否作废">
              <el-radio v-model="officalForm.isEffective" label="0"
                >有效</el-radio
              >
              <el-radio v-model="officalForm.isEffective" label="1"
                >作废</el-radio
              >
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="公章图片">
              <el-upload
                :action="$uploadURL"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :file-list="officalForm.imgUrl"
                list-type="picture-card"
                :on-success="handleSuccess"
                accept="image/png, image/jpeg,image/webp,image/gif,image/bmp,image/x-icon"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="公章描述">
              <el-input
                style="width: 550px"
                :autosize="{ minRows: 2, maxRows: 12 }"
                type="textarea"
                v-model="officalForm.officialSealDesc"
                placeholder="请输入公章描述"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-dialog append-to-body :visible.sync="dialogImg">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogOfficialseal = false">取 消</el-button>
        <el-button type="primary" @click="dialogOfficialsealBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 车辆管理 -->
    <el-dialog
      v-dialogDrag
      :title="carLabel == 'add' ? '新增' : '编辑'"
      :visible.sync="dialogCar"
      width="720px"
    >
      <el-form :inline="true" label-width="90px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="车牌号">
              <el-input v-model="carForm.carNum" placeholder="请输入车牌号">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="品牌">
              <el-input v-model="carForm.brand" placeholder="请输入品牌">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="购买日期">
              <el-date-picker
                style="width: 210px"
                v-model="carForm.buyDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="当前里程数">
              <el-input-number
                :min="0"
                v-model.number="carForm.currentMileage"
              ></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="车辆图片">
              <el-upload
                class="car-uploader"
                :action="$uploadURL"
                accept="image/png, image/jpeg,image/webp,image/gif,image/bmp,image/x-icon"
                :show-file-list="false"
                :on-success="handleCarsuccess"
              >
                <img
                  v-if="carForm.imgUrl"
                  :src="carForm.imgUrl"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="车辆描述">
              <el-input
                style="width: 550px"
                :autosize="{ minRows: 2, maxRows: 12 }"
                type="textarea"
                v-model="carForm.carDesc"
                placeholder="请输入车辆描述"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogCar = false">取 消</el-button>
        <el-button type="primary" :loading="carBtnLoading" @click="dialogCarBtn"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getCompanyAuthCodeByName,
  checkAuth,
  getCompanyMemberAuthCodeByName,
} from "../../../utils/auth";
import drag from "../../../components/drag";
import { quillEditor } from "vue-quill-editor";
import postManage from "../../auth/post/Post.vue";
import {
  tabList,
  operatorProps,
  editorOption,
  officialsealTypeOptions,
  custodianProps,
  handlercustodianOptions,
} from "./toolBarConfig";
import dayjs from "dayjs";
export default {
  components: {
    postManage,
    quillEditor,
  },
  data() {
    return {
      officialsealType: false,
      leftLoading: false,
      carLabel: "add",
      carBtnLoading: false,
      dialogCar: false,
      imageUrlCar: "",
      carForm: {
        id: "",
        carNum: "",
        brand: "",
        buyDate: "",
        carDesc: "",
        imgUrl: "",
        currentMileage: "",
      },
      tableLoadingCar: false,
      tableDataCar: [],
      carTotal: 0,
      carQueryInfo: {
        currPage: 1,
        pageSize: 50,
        condition: {
          carNum: "",
        },
      },
      showRight: false,
      authList: [],
      activeIndex: 0,
      tabList: tabList,
      operatorProps: operatorProps,
      custodianProps: custodianProps,
      accountForm: {
        currency: "",
        balance: "",
        accountNo: "",
        householdNo: "",
        taxNo: "",
        id: "",
      },
      officalTotal: 0,
      officalQueryInfo: {
        currPage: 1,
        pageSize: 50,
        condition: {},
      },
      dialogImageUrl: "",
      dialogImg: false,
      officialsealTypeOptions: officialsealTypeOptions,
      officalForm: {
        id: "",
        officialSeaNo: "",
        operator: "",
        operatorUserCode: [],
        officialSealName: "",
        officialType: "", // tinyint 0合同章，1发票章，2法人章，3公章，4财务章
        officialDate: "",
        custodian: "",
        custodianUserCode: "",
        isEffective: "0",
        count: 0,
        imgUrl: [],
        officialSealDesc: "",
      },
      dialogOfficialseal: false,
      tableDataStamp: [],
      tableLoadingStamp: false,
      currentRowTree: null,
      deptContentDialog: "",
      dialogDept: false,
      editorOption: editorOption,
      deptContent: "",
      currentRowType: "0",
      labelType: "add",
      editTreeId: "",
      parentCode: "",
      treeData: [],
      defaultProps: {
        children: "childrens",
        label: "deptName",
      },
      roleOptions: [],
      postOptions: [],
      toUserOptions: [],
      projectType: "1",
      eventUser: "",
      nodeUser: "",
      userOptions: [],
      nodeForm: {
        preNodeCode: "",
        id: "",
        seq: "",
        preNodeName: "",
        timeNum: "",
      },
      dialogVisibleNode: false,
      addNodeType: "add",
      nodeEventTableLoading: false,
      nodeEventTotal: 0,
      nodeEventQueryInfo: {
        currPage: 1,
        pageSize: 100,
        condition: {
          parentCode: "",
          type: "2",
        },
      },
      nodeEventTable: [], //节点事件表格
      deptPreNodeList: [],
      activePreNodeCode: "", //激活项
      defaultLoading: false,
      defaultRules: {
        preNodeName: [
          {
            required: true,
            message: "请输入节点名称",
            trigger: ["blur", "change"],
          },
        ],
      },
      defaultForm: {
        id: null,
        preNodeName: "",
        deptName: "",
        deptCode: "",
      },
      defaultAdd: true,
      defaultInner: false,
      defaultTotal: 0,
      defaultQueryInfo: {
        currPage: 1,
        pageSize: 10,
        condition: {
          deptCode: null,
        },
      },
      dialogVisibleDefault: false,
      tableLoading: true,
      filterDept: "",
      dialog_edit: false,
      total: 0,
      queryInfo: {
        currPage: 1,
        pageSize: 10,
        condition: {
          deptCode: "",
          keyword: "",
        },
      },
      editObj: {
        id: "",
        name: "",
        sex: "",
        dept: "",
        postName: "",
        phone: "",
        status: "",
        toUserCode: "",
        roleCodeList: [],
        restType: 1,
        signNotice: 1,
      },
      dialog_CreateDept: false,
      deptName: "",
      deptType: "0",
      dialog_CreateEmployee: false,
      employeeForm: {
        loginName: "",
        // phone: "",
        name: "",
        postName: "",
        sex: "1",
        restType: 1,
        signNotice: 1,
        roleCodeList: [],
      },
      typeList: [], //部门列表
      oldTypeList: [],
      activeObj: { deptCode: "", deptName: "" },

      groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
        .engineeringGroup.groupCode,
      groupName: JSON.parse(localStorage.getItem("userInfo")).userInfo
        .engineeringGroup.groupName,
      groupName: "张三",
      tableData: [],
      custodianOptions: [],
    };
  },
  created() {
    console.log(
      JSON.parse(localStorage.getItem("userInfo")).userInfo.engineeringGroup,
      1192
    );
    this.getTreeData();
    this.getTypeList();
    this.getUserList();
    this.getToUserOptions();
    this.getPostOptions();
    this.getRoleOptions();
    this.getCustodianOptions();
    let arr = [];
    this.tabList.forEach((item) => {
      let code = this.getCompanyAuthCodeByName(item.label);
      if (this.checkAuth(code)) {
        arr.push(item);
      }
    });
    this.authList = arr;
    if (arr.length > 0) {
      if (arr.length > 1) {
        this.activeIndex = arr.filter(
          (item) => item.currentRowType == 0 || item.activeIndex == 1
        )[0].activeIndex;
      } else {
        this.activeIndex = arr[0].activeIndex;
      }
    }
  },
  watch: {
    activeIndex: {
      handler(val) {
        this.getDataByActiveIndex(val);
      },
    },
    filterDept(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    // async deleteOneCar(id) {
    //   let res = await this.$http.post("engineeringCar/delete", { id });
    //   if (res.data.code == 200) {
    //     this.$notify({
    //       title: "成功",
    //       message: "删除成功",
    //       type: "success",
    //     });
    //     this.getCarList();
    //   } else {
    //     this.$message.error(res.data.message);
    //   }
    // },
    // addCar() {
    //   for (let i in this.carForm) {
    //     this.carForm[i] = "";
    //   }
    //   this.carLabel = "add";
    //   this.dialogCar = true;
    // },
    // editCar(row) {
    //   this.carLabel = "edit";
    //   for (let i in this.carForm) {
    //     if (i !== "buyDate") {
    //       this.carForm[i] = row[i];
    //     } else {
    //       if (this.carForm[i]) {
    //         this.carForm[i] = dayjs(row[i]).format("YYYY-MM-DD");
    //       }
    //     }
    //   }
    //   this.dialogCar = true;
    // },
    formatData(value) {
      return dayjs(value).format("YYYY-MM-DD");
    },
    async dialogCarBtn() {
      let data = { ...this.carForm };
      data.deptCode = this.currentRowTree.deptCode;
      data.createBy = JSON.parse(localStorage.getItem("userInfo")).userCode;
      // data.createBy = '1001';
      this.carBtnLoading = true;
      let url = "";
      if (this.carLabel == "add") {
        delete data.id;
        url = "engineeringCar/add";
      } else {
        url = "engineeringCar/update";
      }
      let res = await this.$http.post(url, data);
      this.carBtnLoading = false;
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.dialogCar = false;
        this.getCarList();
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleCarsuccess(res, file) {
      console.log(res, "");
      this.carForm.imgUrl = res.data.url;
    },
    getCompanyMemberAuthCodeByName,
    getCompanyMemberAuthCodeByName,
    getCompanyAuthCodeByName: getCompanyAuthCodeByName,
    checkAuth: checkAuth,
    async getCustodianOptions() {
      let res = await this.$http.post("engineeringDept/queryAddressBook", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        // groupCode:1
      });
      console.log(res, "options");
      if (res.data.code == 200) {
        this.custodianOptions = handlercustodianOptions(res.data.data);
        console.log(this.custodianOptions);

        function removeEmptyLabels(options) {
          for (let i = options.length - 1; i >= 0; i--) {
            const option = options[i];
            if (option.label === "") {
              options.splice(i, 1);
            } else if (option.children && option.children.length > 0) {
              removeEmptyLabels(option.children);
            }
          }
        }

        removeEmptyLabels(this.custodianOptions);
        console.log(this.custodianOptions);
      } else {
        this.$message.error(res.data.message);
      }
    },
    async delOneStamp(id) {
      let res = await this.$http.post("engineeringOfficialseal/delete", { id });
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "删除成功",
          type: "success",
        });
      } else {
        this.$message.error(res.data.message);
      }
      this.getStampList();
    },
    editOneStamp(row) {
      this.officialsealType = "edit"; // operator   operatorUserCode imgUrl
      let keys = Object.keys(this.officalForm).filter((item) => {
        return (
          item != "imgUrl" &&
          item != "operator" &&
          item != "operatorUserCode" &&
          item != "officialDate"
        );
      });
      keys.forEach((item) => {
        this.officalForm[item] = row[item];
      });
      this.officalForm.officialDate = dayjs(row.officialDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.officalForm.operatorUserCode = JSON.parse(row.operatorUserCode);
      this.officalForm.operator = JSON.parse(row.operator);
      this.officalForm.imgUrl = row.imgUrl
        .split(",")
        .filter((item) => item != "")
        .map((item) => {
          return { name: item, url: item };
        });
      this.dialogOfficialseal = true;
    },
    addOfficialseal() {
      this.officialsealType = "add";
      this.dialogOfficialseal = true;
    },
    async getTreeData() {
      //获取左侧组织数据
      this.showRight = false;
      this.leftLoading = true;
      let res = await this.$http.post("/engineeringDept/queryTreeList", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        // groupCode:1
      });
      console.log(res,1372);
      this.showRight = true;
      this.leftLoading = false;
      if (res.data.code == 200) {
        if (Array.isArray(res.data.data) && res.data.data.length > 0) {
          //数据校验
          this.treeData = res.data.data;
          let obj = this.currentRowTree
            ? this.currentRowTree
            : res.data.data[0]; // 当前是否有激活项
          this.currentRowTree = obj;
          this.queryInfo.condition.deptCode = obj.deptCode;
          this.currentRowType = obj.type;
          this.activeObj = { deptName: obj.deptName, deptCode: obj.deptCode };
          this.deptId = obj.id;
          setTimeout(() => {
            this.$refs.tree.setCurrentKey(obj.deptCode);
            this.deptContent = this.$refs.tree.getCurrentNode().duty;
            this.deptContentDialog = this.$refs.tree.getCurrentNode().duty;
            this.getInfoByActiveIndex();
          });
        }
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getTable() {
      //  获取人员管理列表数据
      this.tableLoading = true;
      let data = {
        currPage: this.queryInfo.currPage,
        pageSize: this.queryInfo.pageSize,
        condition: {
          keyword: this.queryInfo.condition.keyword,
        },
      };
      if (this.currentRowTree != null) {
        let keyName = "";
        // this.currentRowTree.type == "0"
        //   ? (keyName = "companyCode")
        //   : (keyName = "deptCode");
        // data.condition[keyName] = this.currentRowTree.deptCode;
              //  data.condition["deptCode"] = this.currentRowTree.deptCode;
      }
      data.condition.status = 1;

      let res = await this.$http.post("userInfo/listOrderByDept", data);
      console.log(res,1418);
      if (res.data.code == 200) {
        this.tableLoading = false;
        this.total = res.data.data.count;
        this.tableData = res.data.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getAccountInfo() {
      let res = await this.$http.post("engineeringDeptAccount/queryOne", {
        deptCode: this.currentRowTree.deptCode,
      });
      if (res.data.code == 200) {
        if (res.data.data) {
          for (let i in this.accountForm) {
            this.accountForm[i] = res.data.data[i];
          }
        } else {
          for (let i in this.accountForm) {
            this.accountForm[i] = "";
          }
        }
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getCarList() {
      let data = { ...this.carQueryInfo };
      data.condition.deptCode = this.currentRowTree.deptCode;
      this.tableLoadingCar = true;
      let res = await this.$http.post("engineeringCar/list", data);
      this.tableLoadingCar = false;
      if (res.data.code == 200) {
        this.tableDataCar = res.data.data.data;
        this.carTotal = res.data.data.count;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getStampList() {
      let data = { ...this.officalQueryInfo };
      data.condition.deptCode = this.currentRowTree.deptCode;
      let res = await this.$http.post("engineeringOfficialseal/list", data);
      if (res.data.code == 200) {
        this.tableDataStamp = res.data.data.data;
        this.officalTotal = res.data.data.count;
      } else {
        this.$message.error(res.data.message);
      }
    },
    async dialogOfficialsealBtn() {
      let data = JSON.parse(JSON.stringify(this.officalForm));
      data.createBy = JSON.parse(localStorage.getItem("userInfo")).name;
      data.updateBy = JSON.parse(localStorage.getItem("userInfo")).name;
      data.imgUrl = data.imgUrl
        .map((item) => item.url)
        .filter((item) => item != "")
        .join(",");
      data.deptCode = this.currentRowTree.deptCode;
      data.deptName = this.currentRowTree.deptName;
      if (this.officalForm.officialSeaNo.trim() == "") {
        this.$message.info("请输入公章编号");
        return;
      }
      if (this.officalForm.custodianUserCode == "") {
        this.$message.info("请选择保管人");
        return;
      }
      if (this.officalForm.operatorUserCode == "") {
        this.$message.info("请选择经办人");
        return;
      }

      let list = [];
      this.$refs.operatorUser.getCheckedNodes(true).forEach((item) => {
        list.push(item.pathLabels[item.pathLabels.length - 1]);
      });
      data.operator = list;
      let treeObj = this.$refs.custodian.getCheckedNodes(true)[0];
      data.custodian = treeObj.pathLabels[treeObj.pathLabels.length - 1];
      let url = "engineeringOfficialseal/add";
      if (this.officialsealType == "add") {
        delete data.id;
      } else {
        url = "engineeringOfficialseal/update";
      }
      let res = await this.$http.post(url, data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.dialogOfficialseal = false;
        this.resetOfficialForm();
        this.getStampList();
      } else {
        this.$message.error(res.data.message);
      }
    },
    resetOfficialForm() {
      this.officalForm = {
        id: "",
        officialSeaNo: "",
        operator: "",
        operatorUserCode: [],
        officialSealName: "",
        officialType: "", // tinyint 0合同章，1发票章，2法人章，3公章，4财务章
        officialDate: "",
        custodian: "",
        custodianUserCode: "",
        isEffective: "0",
        count: 0,
        imgUrl: [],
        officialSealDesc: "",
      };
    },
    editorFocus(event) {
      event.enable(false);
    },
    async saveDept() {
      let data = {
        duty: this.deptContentDialog,
        id: this.deptId,
      };
      let res = await this.$http.post("engineeringDept/update", data);
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.dialogDept = false;
        this.getTreeData();
      } else {
        this.$message.error(res.data.message);
      }
    },
    editDept() {
      this.dialogDept = true;
    },
    handleNodeClick(data, node) {
      this.currentRowTree = data;
      this.queryInfo.condition.deptCode = data.deptCode;
      //  this.activeObj.deptCode=data.deptCode
      this.activeObj.deptName = data.deptName;
      this.currentRowType = data.type;
      this.handleActiveIndex();
      this.getInfoByActiveIndex();
    },
    async saveAccountForm() {
      let arr = [
        this.accountForm.taxNo,
        this.accountForm.householdNo,
        this.accountForm.accountNo,
      ];
      let empty = arr.every((item) => item == "");
      if (empty) {
        this.$message.info("请填写对公信息");
        return;
      }
      let data = {
        deptCode: this.currentRowTree.deptCode,
        deptName: this.currentRowTree.deptName,
        createCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
        createName: JSON.parse(localStorage.getItem("userInfo")).name,
        ...this.accountForm,
      };
      let res = await this.$http.post(
        "engineeringDeptAccount/addAccount ",
        data
      );
      if (res.data.code == 200) {
        this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
        });
        this.getAccountInfo();
      } else {
        this.$message.error(res.data.message);
      }
    },
    async getUserList() {
      //负责人下拉选择框
      let res = await this.$http.post("engineeringDept/queryDeptAndEmployee", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        // groupCode:1
      });
      if (res.data.code == 200) {
        this.userOptions = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    delDept(obj) {
      this.$confirm(`确认删除${obj.deptName}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("engineeringDept/deleteDept", {
              id: obj.id,
              deptCode: obj.deptCode,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                if (this.currentRowTree.deptCode == obj.deptCode) {
                  this.currentRowTree = null;
                }
                this.getTreeData();
                this.getTypeList();
              } else {
                this.$message({
                  type: "error",
                  message: res.data.message,
                });
              }
            });
        })
        .catch(() => {});
    },
    deleteOne(id) {
      this.$confirm("确认删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("userInfo/delete", { id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getTable();
            } else {
              this.$message({
                type: "error",
                message: res.data.message,
              });
            }
          });
        })
        .catch(() => {});
    },
    async getEDitInfo(userCode) {
      let res = await this.$http.post("userInfo/queryUserAndRoleInfo", {
        userCode,
      });
      if (res.data.code == 200) {
        let arr = [];
        res.data.data.engineeringRoleList.forEach((item) => {
          arr.push(item.roleCode);
        });
        this.editObj.roleCodeList = arr;
      } else {
        this.$message.error(res.data.message);
      }
    },
    editOne(obj) {
      this.editObj.id = obj.id;
      this.editObj.toUserCode = obj.toUserCode;
      this.editObj.name = obj.name;
      this.editObj.sex = obj.sex;
      this.editObj.status = obj.status;
      this.editObj.postName = obj.postName;
      this.editObj.phone = obj.phone;
      this.editObj.restType = obj.restType;
      this.editObj.signNotice = obj.signNotice;
      let ob = { deptCode: obj.deptCode, deptName: obj.deptName };
      this.editObj.dept = ob;
      this.getEDitInfo(obj.userCode);
      this.dialog_edit = true;
    },
    bindOne(obj) {
      let content = `将${obj.name}绑定为${this.activeObj.deptName}负责人?`;
      this.$confirm(content, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {};
          data.deptCode = obj.deptCode;
          data.userCode = obj.userCode;
          data.userName = obj.name;
          data.userPhone = obj.phone;
          this.$http
            .post("engineeringDept/bingDeptManager", data)
            .then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "提示",
                  message: "绑定成功",
                  type: "success",
                });
                this.getTable();
              } else {
                this.$message.error(res.data.message);
              }
            });
        })
        .catch(() => {});
    },

    //   创建部门
    async createDeptBtn() {
      if (this.deptName.trim() == "") {
        this.$message.error("请输入部门名称");
        return;
      }
      let data = {};
      // data.groupCode = this.groupCode;
      data.groupCode = 1;
      data.groupName = this.groupName;
      data.deptName = this.deptName;
      data.type = this.deptType;
      data.parentCode = this.parentCode;
      if (this.labelType == "edit") {
        data.id = this.editTreeId;
      }
      if (data.parentCode == "") {
        delete data.parentCode;
      }
      let res = await this.$http.post("engineeringDept/createDept", data);
      if (res.data.code == 200) {
        this.dialog_CreateDept = false;
        this.$notify({
          title: "提示",
          message: "保存成功",
          type: "success",
        });
        this.deptName = "";
        this.getTreeData();
      } else {
        this.$message.error(res.data.message);
      }
    },
    //   创建员工
    async createEmployeeBtn() {
      let data = JSON.parse(JSON.stringify(this.employeeForm));
      // data.objectCode = this.groupCode;
      data.objectCode = 1;
      data.objectName = this.groupName;
      data.deptCode = this.currentRowTree.deptCode;
      data.deptName = this.currentRowTree.deptName;
      data.phone = this.employeeForm.loginName;
      data.status = 1;
      data.creator = JSON.parse(localStorage.getItem("userInfo")).userInfo.userCode
      let res = await this.$http.post("userInfo/createEmployeeAccount", data);
      if (res.data.code == 200) {
        this.dialog_CreateEmployee = false;
        this.$notify({
          title: "提示",
          message: "保存成功",
          type: "success",
        });

        this.employeeForm.loginName = "";
        this.employeeForm.name = "";
        this.employeeForm.postName = "";
        this.employeeForm.sex = "1";
        this.employeeForm.signNotice = 1;
        this.employeeForm.restType = 1;
        this.employeeForm.roleCodeList = [];
        this.getTable();
      } else {
        this.$message.error(res.data.message);
      }
    },

    addTopLevelTreeData() {
      this.labelType = "add";
      this.dialog_CreateDept = true;
    },
    async editBtn() {
      let data = JSON.parse(JSON.stringify(this.editObj));
      data.deptCode = this.editObj.dept.deptCode;
      data.deptName = this.editObj.dept.deptName;
      delete data.dept;
      let res = await this.$http.post("userInfo/updateEmployeeAccount", data);
      if (res.data.code == 200) {
        this.dialog_edit = false;
        this.$notify({
          title: "提示",
          message: "保存成功",
          type: "success",
        });
        this.getTable();
      } else {
        this.$message.error(res.data.message);
      }
    },

    addTreeNode(node, data) {
      event.stopPropagation(); //阻止事件冒泡
      this.labelType = "add";
      this.parentCode = data.deptCode;
      this.dialog_CreateDept = true;
    },

    editTreeNode(node, data) {
      event.stopPropagation(); //阻止事件冒泡
      this.labelType = "edit";
      this.deptName = data.deptName;
      this.deptType = data.type;
      this.editTreeId = data.id;
      this.dialog_CreateDept = true;
    },
    delTreeNode(node, data) {
      event.stopPropagation();
      if (node.childNodes.length > 0) {
        this.$message.info(
          data.deptName + `存在${node.childNodes.length}个二级部门,不可删除！`
        );
      } else {
        this.$confirm("确认删除" + data.deptName + "?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.$http
            .post("engineeringDept/deleteDept", { id: data.id })
            .then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "成功",
                  message: "删除成功",
                  type: "success",
                });
                this.getTreeData();
              } else {
                this.$message.error(res.data.message);
              }
            });
        });
      }
    },
    // 监听 pagesize改变的事件
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getTable();
    },
    // 监听 页码值 改变事件
    handleCurrentChange(newSize) {
      this.queryInfo.currPage = newSize;
      this.getTable();
    },
    // 监听 pagesize改变的事件
    handleSizeChangeOfficial(newSize) {
      this.officalQueryInfo.pageSize = newSize;
      this.getStampList();
    },
    // 监听 页码值 改变事件
    handleCurrentChangeOfficial(newSize) {
      this.officalQueryInfo.currPage = newSize;
      this.getStampList();
    },
    // 监听 pagesize改变的事件
    handleSizeChangeEvent(newSize) {
      this.nodeEventQueryInfo.pageSize = newSize;
      this.getDeptPreNodeTable();
    },
    // 监听 页码值 改变事件
    handleCurrentChangeEvent(newSize) {
      this.nodeEventQueryInfo.currPage = newSize;
      this.getDeptPreNodeTable();
    },
    // 监听 pagesize改变的事件
    handleSizeChangeCar(newSize) {
      this.carQueryInfo.pageSize = newSize;
      this.getCarList();
    },
    // 监听 页码值 改变事件
    handleCurrentChangeCar(newSize) {
      this.carQueryInfo.currPage = newSize;
      this.getCarList();
    },
    async getTypeList() {
      let res = await this.$http.post("engineeringDept/queryList", {
        groupCode: 1,
      });
      this.typeList = res.data.data;
      this.oldTypeList = res.data.data;
    },
    renderContent(h, { node, data, store }) {
      return (
        <span
          title={node.label + "(" + data.userCount + "人)"}
          class="custom-tree-node"
        >
          <span>
            <span>{node.label + "(" + data.userCount + "人)"}</span>
          </span>
          <span>
            <i
              on-click={(_) => {
                this.addTreeNode(node, data);
              }}
              style="margin-right:8px"
              class="el-icon-plus icon2 icons"
            ></i>
            <i
              on-click={(_) => {
                this.editTreeNode(node, data);
              }}
              style="margin-right:8px"
              class="el-icon-edit icon2 icons"
            ></i>
            <i
              on-click={(_) => {
                this.delTreeNode(node, data);
              }}
              class="el-icon-delete icon2 icons"
            ></i>
          </span>
        </span>
      );
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.deptName.indexOf(value) !== -1;
    },
    async getRoleOptions() {
      let res = await this.$http.post("engineeringRole/queryList", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        groupCode: 1,
      });
      this.roleOptions = res.data.data;
    },
    async getPostOptions() {
      let res = await this.$http.post("post/queryList", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        groupCode: 1,
      });
      if (res.data.code == 200) {
        this.postOptions = res.data.data;
      } else {
        this.$message.error(res.data.mes);
      }
    },
    async getToUserOptions() {
      let res = await this.$http.post("engineeringDept/queryDeptAndEmployee", {
        groupCode: JSON.parse(localStorage.getItem("userInfo")).userInfo
          .engineeringGroup.groupCode,
        groupCode: 1,
      });
      if (res.data.code == 200) {
        this.toUserOptions = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },
    handleActiveIndex2() {
      this.deptId = this.currentRowTree.id;
      this.deptContent = this.currentRowTree.duty;
      this.deptContentDialog = this.currentRowTree.duty;
    },
    getInfoByActiveIndex() {
      if (this.activeIndex == 1) {
        //人员管理
        this.getTable();
      }
      if (this.activeIndex === 2 && this.currentRowTree.type == 1) {
        // 部门职责
        this.handleActiveIndex2();
      }
      if (this.activeIndex == 3 && this.currentRowTree.type == 0) {
        //岗位管理
        this.setActiveIndex3();
      }
      if (this.activeIndex == 4 && this.currentRowTree.type == 0) {
        this.getStampList();
      }
      if (this.activeIndex == 5 && this.currentRowTree.type == 0) {
        this.getAccountInfo();
      }
      if (this.activeIndex == 6 && this.currentRowTree.type == 0) {
        this.getCarList();
      }
    },
    setActiveIndex3() {
      this.activeObj.deptCode = this.currentRowTree.deptCode;
      this.activeObj.deptName = this.currentRowTree.deptName;
    },
    getDataByActiveIndex(val) {
      if (val == 1) {
        this.getTable();
        return;
      }
      if (this.currentRowTree) {
        if (this.currentRowTree.type == 0) {
          // 3,4,5,6
          switch (val) {
            case 3:
              this.setActiveIndex3();
              break;
            case 4:
              this.getStampList();
              break;
            case 5:
              this.getAccountInfo();
              break;
            case 6:
              this.getCarList();
              break;
          }
        }
        if (this.currentRowTree.type == 1) {
          this.handleActiveIndex2();
        }
      }
    },
    handleActiveIndex() {
      if (this.activeIndex != 1) {
        console.log(this.currentRowTree.type, "this.currentRowTree.type");
        if (this.currentRowTree.type == 0 && this.activeIndex == 2) {
          // this.activeIndex=1
          console.log(
            this.authList.filter((item) => item.activeIndex != 2),
            "=="
          );
          let arr = this.authList.filter((item) => item.activeIndex != 2);
          this.activeIndex = arr[0].activeIndex || 0;
        }
        if (this.currentRowTree.type == 1 && this.activeIndex != 2) {
          // this.activeIndex=1
          this.activeIndex =
            this.authList.filter((item) => item.currentRowType == 1)[0]
              .activeIndex || 0;
        }
      }
      console.log(this.activeIndex, "aaa");
    },
    handleRemove(file) {
      let index = this.officalForm.imgUrl.findIndex(
        (item) => item.url == file.url
      );
      this.officalForm.imgUrl.splice(index, 1);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogImg = true;
    },
    handleSuccess(res) {
      if (res.code == 200) {
        this.officalForm.imgUrl.push({ name: res.data.url, url: res.data.url });
      } else {
        this.$message.error(res.message);
      }
    },
    //解绑
    cancelOne(obj) {
      let content = `不再设置${obj.name}为${this.activeObj.deptName}负责人?`;
      this.$confirm(content, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let data = {};
          data.deptCode = obj.deptCode;
          data.userCode = obj.userCode;
          this.$http
            .post("engineeringDept/cancelBingDeptManager", data)
            .then((res) => {
              if (res.data.code == 200) {
                this.$notify({
                  title: "提示",
                  message: "绑定成功",
                  type: "success",
                });
                this.getTable();
              } else {
                this.$message.error(res.data.message);
              }
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .noToolBar {
  padding: 0;
  .ql-editor {
    padding-top: 0;
  }
  .ql-toolbar {
    display: none;
  }
  .ql-container.ql-snow {
    border: none;
    padding-top: 0;
  }
}
/deep/ .el-card__body {
  padding-bottom: 0;
}
.cards {
  width: 100%;
  display: flex;
  flex-grow: 0;
  .card_left {
    width: 340px;
    flex-shrink: 0;
    .top {
      display: flex;
      /deep/ .el-input__inner {
        height: 36px !important;
        line-height: 36px !important;
      }
      .el-button {
        height: 36px;
        border-radius: 7px;
      }
      .create_btn {
        background: #02a7f0;
        margin-left: 12px;
        i {
          font-size: 18px;
          font-weight: 700;
          color: #fff;
        }
      }
    }
    .list {
      margin-top: 16px;
      margin-bottom: 0;
      height: calc(100vh - 150px);
      overflow: auto;
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        user-select: none;
        cursor: pointer;
        height: 50px;
        list-style: none;
        background-color: #f2f2f2;
        color: #333;
        line-height: 50px;
        padding-right: 16px;
        padding-left: 40px;
        i {
          display: none;
        }
        > div {
          width: 220px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      li.active {
        background-color: #b2e4fb;
        font-weight: 600;
      }
      li:hover {
        background: darken(#f2f2f2, 15%);
        i {
          display: block;
        }
        i:hover {
          font-weight: 600;
        }
      }
      li.active:hover {
        background-color: #b2e4fb;
      }
    }
  }
  .card_right {
    margin-left: 16px;
    flex-grow: 0;
    width: calc(100% - 340px);
    .top {
      color: #555;
      font-size: 20px;
      font-weight: 550;
      font-family: "微软雅黑 Bold", 微软雅黑, sans-serif;
    }
    .two {
      display: flex;
      width: 100%;
      margin-top: 12px;
      font-weight: 550;
      font-size: 15px;
      padding-bottom: 10px;
      border-bottom: 1px solid #d7d7d7;
      .item {
        position: relative;
        width: fit-content;
        color: #999;
        cursor: pointer;
      }
      .activeItem {
        color: #02a7f0;
      }
      .item.activeItem::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        left: 0;
        bottom: -12px;
        background: #02a7f0;
      }
    }
    .table_box {
      margin-top: 10px;
      padding-left: 6px;
      width: 100%;
      .table_title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .left {
          display: flex;
          .el-button {
            background: #02a7f0;
            color: #fff;
            border-radius: 4px;
            margin-left: 12px;
          }
        }
        .right {
          .new_btn {
            background: #02a7f0;
            color: #fff;
            border-radius: 4px;
            i {
              font-weight: 700;
            }
          }
        }
      }
      .table_main {
        width: 100%;
      }
    }
    .userManage {
      margin-top: 40px;
      padding-left: 6px;
      width: 100%;

      display: flex;
      .leftt {
        flex-shrink: 0;
        border: 1px solid #e3e3e3;
        width: 250px;
        height: calc(100vh - 258px);
        .topTitle {
          padding: 18px 10px;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          width: 100%;
        }
        ul.items {
          height: calc(100% - 90px);
          margin-top: 0;
          overflow: auto;
          li {
            cursor: pointer;
            background: #fff;
            list-style: none;
            padding: 10px 12px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span {
              padding-left: 6px;
            }
            .hoverIcon {
              display: none;
            }
          }
          li:hover {
            background: #f1f1f1;
            .hoverIcon {
              display: block;
            }
          }
          li.activeItem {
            // background: #f2f2f2;
            background: darken(#f2f2f2, 5%);
          }
        }
      }
      > .right {
        box-sizing: border-box;
        // height: calc(100vh - 260px);
        width: calc(100% - 260px);
        padding: 18px;
        margin-left: 20px;
        border: 1px solid #e3e3e3;
        .nodeEvent {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}
/deep/ .el-form-item__label::after {
  content: ":";
  padding-left: 2px;
}
/deep/ .online {
  color: #7f7f7f;
}
/deep/ .online::before {
  content: "";
}
/deep/ .samllPadding {
  padding: 0 4px;
}
/deep/ .categoryCompany {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #02a7f0;
  }
  margin-top: 16px;
  margin-bottom: 0;
  height: calc(100vh - 190px);
  overflow: auto;
  //    border: 1px dashed #02a7f0;
  .el-tree-node__content {
    height: 40px;
    .el-tree-node__label {
      font-size: 20px;
    }
  }
}
/deep/ .custom-tree-node {
  font-size: 18px;
  i {
    display: none;
  }
  i:hover {
    color: #02a7f0;
  }
}
/deep/ .el-tree-node__content:hover {
  i {
    display: inline-block;
  }
}
/deep/ .custom-tree-node {
  span {
    max-width: 230px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}
/deep/ .is-leaf {
  span {
    max-width: 230px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    word-break: break-all;
  }
}
/deep/ .block-info {
  margin-top: 10px;
  position: relative;
  margin-left: 12px;
}
.block-info::before {
  content: "";
  position: absolute;
  left: -10px;
  display: block;
  width: 4px;
  top: 2px;
  height: 90%;
  background: #02a7f0;
}
.dept-box {
  height: calc(100vh - 255px);
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 8px;
  border: 1px solid #e6e9f3;
  .box-title {
    padding: 16px;
    position: relative;
    display: flex;
    justify-content: center;
    .right-btn {
      position: absolute;
      right: 10px;
      top: 10px;
    }
    > span {
      font-size: 18px;
      font-weight: bold;
    }
  }
  .box-inner {
    height: calc(100vh - 320px);
    border: 1px solid #e6e9f3;
  }
  .box-inner::-webkit-scrollbar {
    width: 0;
  }
}
/deep/ .ql-container {
  height: calc(100vh - 360px);
  overflow: auto;
}
/deep/ .ql-editor {
  height: 100%;
}
/deep/ .deptDialog {
  .el-dialog__header {
    text-align: left;
    span {
      font-size: 18px;
      font-weight: bold;
    }
  }
}
/deep/ .button-img {
  height: 13px;
  margin-right: 5px;
}
/deep/ .el-upload--picture-card {
  height: 120px;
  width: 120px;
  line-height: 120px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  height: 120px;
  width: 120px;
}
/deep/ .el-upload-list__item {
  transition: none !important;
}
/deep/ .flex-start {
  justify-content: flex-start !important;
}
/deep/ .line-height44 {
  .item__label {
    font-size: 16px;
  }
  .el-form-item__content {
    line-height: 44px;
  }
}
/deep/ .tabsList + div {
  margin-left: 16px;
}
/deep/ .car-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .car-uploader .el-upload:hover {
  border-color: #409eff;
}
/deep/.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
</style>
